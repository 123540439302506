<template lang="pug">
.accounting-page.flex.column
  .input.flex.align-center.gap-10.mb-20
    .col.flex.column.align-start.gap-8
      .title.font-size-12 區間
      el-date-picker.date-range-input(@change="changeDate"
        v-model="times"
        type="daterange"
        range-separator="至")
    .col.flex.column.align-start.gap-8
      .title.font-size-12 專業人士
      pro-selector(v-model="professional", show-all, public-only)
    .col.flex.column.align-start.gap-8
      .title.font-size-12 財管
      .account-month-info.flex.align-center.gap-4
        span {{ searchMonthText }}
        i.el-icon-edit.clickable(@click="showAccountingMonthDialog")
    .col.flex.column.align-start.gap-8
      .title.font-size-12 支付狀態
      el-select(v-model="paymentStatus", @change="loadData")
        el-option(value="", label="全部")
        el-option(value="notpaid", label="尚未支付")
        el-option(value="cash", label="現金支付")
        el-option(value="3rdparty", label="藍新金流")
        el-option(value="credit", label="信用卡支付")
        el-option(value="transfer", label="轉帳支付")
        el-option(value="project", label="專案支付")
  el-tabs.flex.column.fill(type="border-card", @tab-click="tabChange", v-model="tabPage", :lazy="true")
    el-tab-pane(label="自費", name="self")
      .flex.align-center
        el-button(type="primary", size="mini", @click="selectAll('selfList')") 選擇全部
        el-button(type="primary", size="mini", @click="setMonth('selfList')") 批次設定財管月
      reserve-list#self(:data="selfReserveDatas", @edit="openEdit", ref="selfList", @refresh="loadSelfData")
      .pagination.flex.align-center.justify-end
        el-pagination(
          layout="total, sizes, prev, pager, next, ->, jumper"
          :page-size.sync="selfLimit"
          :current-page.sync="selfCurrentPage"
          :total="selfTotal"
        )
    el-tab-pane(label="專案", name="project")
      .flex.align-center
        el-button(type="primary", size="mini", @click="selectAll('projectList')") 選擇全部
        el-button(type="primary", size="mini", @click="setMonth('projectList')") 批次設定財管月
      reserve-list#project(:data="projectReserveDatas", @edit="openEdit", ref="projectList", is-project)
      .pagination.flex.align-center.justify-end
        el-pagination(
          layout="total, sizes, prev, pager, next, ->, jumper"
          :page-size.sync="projectLimit"
          :current-page.sync="projectCurrentPage"
          :total="projectTotal"
        )
    el-tab-pane(label="活動", name="activity")
      .flex.align-center
        el-button(type="primary", size="mini", @click="selectAll('activityList')") 選擇全部
        el-button(type="primary", size="mini", @click="setMonth('activityList')") 批次設定財管月
      activity-list#activity(:data="activityDatas", @edit="openActivityEdit", ref="activityList")
      .pagination.flex.align-center.justify-end
        el-pagination(
          layout="total, sizes, prev, pager, next, ->, jumper"
          :page-size.sync="activityLimit"
          :current-page.sync="activityCurrentPage"
          :total="activityTotal"
        )
  appointment-edit(
    ref="appointmentEdit"
    @finish="loadData"
  )
  activity-editor(
    ref="activityEditor"
    @finish="loadData"
  )
  el-dialog(
    custom-class="no-close"
    :show-close="false"
    title="設定財管搜尋月份"
    width="350px"
    :visible.sync="monthDialogVisible")
    .flex.column.conditions.gap-20.p-20
      el-radio(v-model="searchMonthTypeInDialog", label="0") 不設定條件
      el-radio(v-model="searchMonthTypeInDialog", label="1") 僅顯示未設定財管月
      el-radio(v-model="searchMonthTypeInDialog", label="2") 指定財管月
      el-date-picker.minimize-picker(
        v-if="searchMonthTypeInDialog === '2'"
        type="month", v-model="accountingMonthInDialog")
      el-button(@click="closeAccountingMonthDialog") OK
  el-dialog(
    :show-close="false"
    title="批次設定財管月份"
    width="350px"
    :visible.sync="monthValueDialogVisible")
    .flex.column.conditions.gap-20
      el-date-picker.minimize-picker(
        type="month", v-model="monthToSet")
      .flex.align-center.gap-10
        el-button(type="primary", @click="closeSetMonthDialog(true)") 儲存
        el-button(@click="closeSetMonthDialog(false)") 取消
</template>

<script>
import {
  getAccountingAppointments,
  getAccountingActivities,
} from '@/api/accounting';
import AppointmentEditor from '@/components/drawers/AppointmentEdit.vue';
import ActivityEditorVue from '@/components/drawers/ActivityEditor.vue';
import ReserveList from './ReserveList.vue';
import ActivityList from './ActivityList.vue';

export default {
  components: {
    'reserve-list': ReserveList,
    'activity-list': ActivityList,
    'appointment-edit': AppointmentEditor,
    'activity-editor': ActivityEditorVue,
  },
  data() {
    return {
      times: [],
      professional: '',
      accountingMonth: new Date(),
      paymentStatus: '',
      tabPage: 'self',

      selfReserveDatas: [],
      selfLimit: 10,
      selfCurrentPage: 1,
      selfTotal: 0,
      projectReserveDatas: [],
      projectLimit: 10,
      projectCurrentPage: 1,
      projectTotal: 0,
      activityDatas: [],
      activityLimit: 10,
      activityCurrentPage: 1,
      activityTotal: 0,

      monthDialogVisible: false,
      searchMonthType: '0',

      accountingMonthInDialog: new Date(),
      searchMonthTypeInDialog: '0',

      setMonthTarget: '',
      monthValueDialogVisible: false,
      monthToSet: null,
    };
  },
  computed: {
    searchMonthText() {
      if (this.searchMonthType === '0') {
        return '不設定條件';
      } if (this.searchMonthType === '1') {
        return '僅顯示未設定財管月';
      }
      if (this.accountingMonth === null) {
        return '不設定條件';
      }
      return `${this.accountingMonth.getFullYear()}年${this.accountingMonth.getMonth() + 1}月`;
    },
  },
  watch: {
    accountingMonth() {
      if (this.accountingMonth === null) {
        this.searchMonthType = '0';
      }
    },
    selfLimit() {
      this.loadData();
    },
    projectLimit() {
      this.loadData();
    },
    activityLimit() {
      this.loadData();
    },
    professional() {
      this.loadData();
    },
    selfCurrentPage() {
      this.loadData();
    },
    projectCurrentPage() {
      this.loadData();
    },
    activityCurrentPage() {
      this.loadData();
    },
  },
  methods: {
    closeSetMonthDialog(save) {
      this.monthValueDialogVisible = false;
      if (!save) {
        this.monthToSet = null;
        return;
      }
      this.$refs[this.setMonthTarget].$emit('set-month', new Date(this.monthToSet));
      this.monthToSet = null;
      this.setMonthTarget = '';
    },
    selectAll(target) {
      this.$refs[target].$emit('select-all');
    },
    setMonth(target) {
      if (this.$refs[target].getCheckedList(target).length <= 0) {
        alert('請選擇至少一個預約');
        return;
      }
      this.setMonthTarget = target;
      this.monthValueDialogVisible = true;
    },
    showAccountingMonthDialog() {
      this.monthDialogVisible = true;
      this.searchMonthTypeInDialog = this.searchMonthType;
      this.accountingMonthInDialog = this.accountingMonth;
    },
    closeAccountingMonthDialog() {
      this.monthDialogVisible = false;
      this.accountingMonth = this.accountingMonthInDialog;
      if (this.accountingMonthInDialog == null && this.searchMonthType === '2') {
        this.searchMonthType = '0';
        return;
      }
      this.searchMonthType = this.searchMonthTypeInDialog;
      this.loadData();
    },
    setupDefaultTime() {
      const start = new Date();
      const date = start.getDate();

      let monthShift = -1;
      if (date >= 16) {
        monthShift = 0;
      }
      start.setMonth(start.getMonth() + monthShift);
      start.setDate(1);
      start.setHours(0, 0, 0, 0);

      const tmp = new Date(start);
      tmp.setMonth(tmp.getMonth() + 1);
      const end = new Date(tmp.getTime() - 1);
      this.times = [start, end];
    },
    changeAccountingMonth() {
      console.log(this.accountingMonth);
    },
    changeDate() {
      this.loadData();
    },
    tabChange() {
      return this.loadData();
    },
    getSelfPaidFilter() {
      return {
        ...this.getGeneralFilter(),
        payment_type: 'self_paid',
        page: this.selfCurrentPage - 1,
        limit: this.selfLimit,
      };
    },
    getProjectPaidFilter() {
      return {
        ...this.getGeneralFilter(),
        payment_type: 'project',
        page: this.projectCurrentPage - 1,
        limit: this.projectLimit,
      };
    },
    getGeneralFilter() {
      this.times[1].setHours(23, 59.0, 59, 999);
      const filter = {
        start_at: parseInt(this.times[0].getTime() / 1000, 10),
        end_at: parseInt(this.times[1].getTime() / 1000, 10),
        payment_status: this.paymentStatus,
        professional: this.professional,
        search_accounting_month: this.searchMonthType === '1' || this.searchMonthType === '2',
      };
      if (this.searchMonthType === '2') {
        filter.accounting_month = this.accountingMonth.getMonth() + 1;
        filter.accounting_year = this.accountingMonth.getFullYear();
      }
      return filter;
    },
    async loadData() {
      return this.$execWithLoading(async () => {
        if (this.tabPage === 'self') {
          await this.loadSelfData();
        } else if (this.tabPage === 'project') {
          await this.loadProjectData();
        } else if (this.tabPage === 'activity') {
          await this.loadActivityData();
        }
        setTimeout(() => {
          this.$forceUpdate();
        }, 100);
      }, (e) => {
        this.$showAxiosException('載入資料失敗', e);
      });
    },
    async loadSelfData() {
      const rsp = await getAccountingAppointments(this.getSelfPaidFilter());
      this.selfReserveDatas = rsp.appointments;
      this.selfTotal = rsp.meta.total;
    },
    async loadProjectData() {
      const rsp = await getAccountingAppointments(this.getProjectPaidFilter());
      this.projectReserveDatas = rsp.appointments.map((a) => ({
        ...a,
        projectName: `${a.organization?.name} - ${a.project?.name}`,
      }));
      this.projectTotal = rsp.meta.total;
    },
    async loadActivityData() {
      const filter = {
        ...this.getGeneralFilter(),
        page: this.activityCurrentPage - 1,
        limit: this.activityLimit,
      };
      const rsp = await getAccountingActivities(filter);
      this.activityDatas = rsp.activities;
      this.activityTotal = rsp.meta.total;
    },
    openEdit(appointment) {
      this.$refs.appointmentEdit.$emit('show', appointment);
    },
    openActivityEdit(activity) {
      this.$refs.activityEditor.$emit('show', activity);
    },
    checkRoute() {
      const hashPart = window.location.hash.substring(1);
      const parts = hashPart.split('?');
      if (parts.length === 1) {
        return false;
      }
      const searchParams = new URLSearchParams(parts[1]);

      console.log(searchParams);
      const professionalID = parseInt(searchParams.get('professional'), 10);
      const target = searchParams.get('type');
      const year = searchParams.get('year');
      const month = searchParams.get('month');
      const start = parseInt(searchParams.get('start'), 10);
      const end = parseInt(searchParams.get('end'), 10);
      console.log(parts[1], {
        professionalID, target, year, month, start, end,
      });

      this.professional = professionalID;
      this.searchMonthType = '2';
      this.accountingMonthInDialog = new Date(`${year}/${month}/1`);
      this.times = [new Date(start * 1000), new Date(end * 1000)];
      this.tabPage = target;
      if (target === 'selfPaid') {
        this.tabPage = 'self';
      } else if (target === 'projectPaid') {
        this.tabPage = 'project';
      }

      return searchParams.has('professional');
    },
  },
  mounted() {
    this.setupDefaultTime();
    console.log(this.checkRoute());
    this.loadSelfData();
  },
};
</script>

<style lang="scss" scoped>

.date-range-input {
  min-width: 320px;
}
</style>

<style lang="scss">
.accounting-page {
  .el-tabs__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tab-pane {
      flex: 1;
      display: flex;
      flex-direction: column;
      .el-table {
        flex: 1;
        display: flex;
        flex-direction: column;
        .el-table__body-wrapper {
          flex: 1;
        }
      }
    }
  }
  .account-month-info {
    height: 40px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    padding: 5px 10px;
    background: white;
  }
  .all-btn {
    max-width: 80px;
  }
}
</style>

<style lang="scss">
.accounting-page {
  .el-tabs {
    overflow: hidden;
    .el-tabs__content {
      & > div {
        overflow: hidden;
        .el-table__body-wrapper {
          overflow-y: scroll;
          overflow-y: auto;
        }
      }
    }
  }
}
</style>
