<template lang="pug">
#app.safari-wrap.flex.column
  template(v-if="ready")
    .header.flex(:class="{'show': isLogin}")
      el-tooltip(v-if="version", placement="bottom", :content="version")
        .logo(@click="$copy(version, true)")
      .logo(v-else)
      el-menu.menu(mode="horizontal", :default-active="menu", @select="changePage")
        template(v-if="isAdmin || isAdvStaff")
          el-menu-item(index="Revenue", id="menu-Revenue")
            a.link(href="#/revenue") 營收資訊
        el-menu-item(index="Reserve", id="menu-Reserve")
          a.link(href="#/reserve") 預約管理
        el-menu-item(index="Space", id="menu-Space")
          a.link(href="#/space") 空間管理
        el-menu-item(index="Users", id="menu-Users")
          a.link(href="#/users") 個案管理
        template(v-if="isAdmin || isAdvStaff")
          el-menu-item(index="Activity", id="menu-Activity")
            a.link(href="#/activity") 活動管理
          el-menu-item(index="Setting", id="menu-Setting")
            a.link(href="#/setting") 設定清單
      el-dropdown.header-dropdown(@command="doHeaderCommand")
        .user.flex.align-center.gap-12
          i.el-icon-user-solid
          .username {{ userDisplayName }}
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item(command="account") 帳號管理
          el-dropdown-item(command="logout") 登出
    router-view.content(v-if="ready")
    template(v-for="(user, idx) in detailUsers")
      user-viewer.user-viewer(
        show-copy
        :uid="user.id"
        :style="{'z-index': idx + 10}"
      )
  //- user-viewer.user-viewer(
  //-   show-copy
  //-   :class="{'show': lastDetailUser !== undefined}"
  //-   @close="lastDetailUser = undefined"
  //-   :uid="lastDetailUser?.id")
  self-pwd-editor(ref="pwdEditor")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getProfile } from '@/api/auth';
import { getProfessionalList } from '@/api/professionals';
import UserViewer from '@/components/UserViewer.vue';
import SelfPasswordEditorVue from '@/components/drawers/SelfPasswordEditor.vue';
import router from './router';

export default {
  components: {
    'user-viewer': UserViewer,
    'self-pwd-editor': SelfPasswordEditorVue,
  },
  data() {
    return {
      menu: 'Home',
      ready: false,
      detailUser: undefined,
      detailUsers: [],
      version: undefined,
    };
  },
  computed: {
    ...mapGetters(['userDisplayName', 'isLogin', 'isAdmin', 'isAdvStaff']),
    lastDetailUser() {
      if (this.detailUsers.length > 0) {
        return this.detailUsers[this.detailUsers.length - 1];
      }
      return undefined;
    },
  },
  watch: {
    $route() {
      this.detailUser = undefined;
      this.detailUsers = [];
      this.checkRoute();
    },
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    doHeaderCommand(cmd) {
      switch (cmd) {
        case 'logout':
          this.logout();
          return;
        case 'account':
          this.$refs.pwdEditor.$emit('show');
          return;
        default:
          console.log('invalid cmd', cmd);
      }
    },
    logout() {
      this.setUserInfo(undefined);
      this.$router.push('/login');
    },
    async loadData() {
      return this.$execWithLoading(async () => {
        const profile = await getProfile();
        this.setUserInfo(profile);
      });
    },
    changePage(key) {
      this.$router.push(`/${key.toLowerCase()}`).catch(this.handleDuplicatedRoute);
    },
    checkRoute() {
      router.forEach((r) => {
        let find = false;
        if (r.name === this.$route.name) {
          find = true;
        }
        if (!find && r.children !== undefined) {
          r.children.forEach((child) => {
            if (child.name === this.$route.name) {
              find = true;
            }
          });
        }
        if (find) {
          this.menu = r.name;
          if (r.needAuth && !this.isLogin) {
            this.$router.push('/login');
          }
        }
      });
    },
    handleDuplicatedRoute(error) {
      if (error.name !== 'NavigationDuplicated') {
        throw error;
      }
      this.ready = false;
      setTimeout(() => {
        this.ready = true;
        this.$forceUpdate();
      }, 100);
    },
  },
  async mounted() {
    function safariHacks() {
      const windowsVH = window.innerHeight / 100;
      document.querySelector('.safari-wrap').style.setProperty('--vh', `${windowsVH}px`);
      window.addEventListener('resize', () => {
        document.querySelector('.safari-wrap').style.setProperty('--vh', `${windowsVH}px`);
      });
    }

    window.addEventListener('resize', () => {
      safariHacks();
    });

    this.$root.$on('show-user', (user) => {
      this.detailUser = user;
      this.detailUsers.push(user);
    });
    this.$root.$on('close-user', () => {
      if (this.detailUsers.length === 0) {
        this.$router.push('/users');
        return;
      }
      this.detailUsers = this.detailUsers.slice(0, this.detailUsers.length - 1);
    });

    this.version = await this.$getVersion();

    await this.loadData();
    this.checkRoute();
    this.ready = true;
    await getProfessionalList();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-regular-color;
  font-size: 14px;
  .header {
    max-height: 0;
    transition: transform .3s ease-in-out;
    border-bottom: solid 1px #e6e6e6;
    overflow: hidden;

    &.show {
      max-height: $header-height;
    }
    .logo {
      flex: 0 0 82px;
      background-image: url('/logo.png');
      background-position: 50% 50%;
      background-size: 40px 35px;
      background-repeat: no-repeat;
    }
    .menu {
      flex: 1;
      border: none;
      .link {
        text-decoration: none;
      }
    }
    .user {
      padding: 0 20px;
    }
    .logout {
      padding: 0 20px;
      color: $text-secondary-color;
    }
  }
  .content {
    flex: 1;
    overflow-x: hidden;
    background: #F9F9F9;
  }
  .user-viewer {
    position: absolute;
    opacity: 1;
    pointer-events: all;
    transition: all .5s ease-in-out;
    top: $header-height;
    left: 0;
    z-index: 2;
  }
  .header-dropdown {
    display: flex;
    cursor: pointer;
  }
}

</style>
