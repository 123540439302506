<template lang="pug">
el-drawer(
  :title="title"
  :visible.sync="drawer"
  :close-on-press-escape="false"
  :wrapperClosable="false"
  direction="rtl"
  :before-close="handleClose")
  .drawer.flex.column.gap-20
    .data.flex.column.align-stretch.gap-6
      .title 姓名*
      el-input(v-model.trim="name", placeholder="請輸入姓名")
    .data.flex.column.align-stretch.gap-6
      .title Email*
      .alert.text-danger(v-if="origData && email !== origData?.email") 變更 email 需重新進行雙重驗證的綁定
      el-input(
        v-model.trim="email", placeholder="請輸入 Email")
    .data.flex.column.align-stretch.gap-6
      .title 角色*
      el-select(v-model="role")
        el-option(value="admin", label="管理員")
        el-option(value="staff", label="個管師")
        el-option(value="advstaff", label="進階個管師")
    .data.flex.column.align-stretch.gap-6(v-if="role && role === 'staff'")
      .title 登記館別*
      place-selector.place-input(
        multiple
        :showAll="false"
        v-model="branch")
    .op.flex.align-center-justify-stretch.gap-10
      el-button.button(@click="cancel") 取消
      template(v-if="editMode")
        el-button.button(@click="save", type="primary", :disabled="!valid") 更新
      template(v-else)
        el-button.button(@click="save", type="primary", :disabled="!valid") 新增
</template>

<script>
import {
  newAccount,
  updateAccount,
} from '@/api/management';
import { getList } from 'country-list-with-dial-code-and-flag';

export default {
  name: 'account-editor',
  data() {
    const countries = getList();
    countries.sort((a, b) => (a.dial_code > b.dial_code ? 1 : -1));
    const idx = countries.findIndex((c) => c.dial_code === '+886');
    const tw = countries[idx];
    countries.splice(idx, 1);
    countries.unshift(tw);
    return {
      origData: undefined,
      drawer: false,
      countryList: countries,

      name: '',
      email: '',
      country: '+886',
      phone: '',
      role: '',
      branch: undefined,
    };
  },
  computed: {
    title() {
      if (this.editMode) {
        return '編輯帳號';
      }
      return '新增帳號';
    },
    valid() {
      if (this.name === '') {
        return false;
      }
      if (this.email === '') {
        return false;
      }
      if (this.role === '') {
        return false;
      }
      return true;
    },
    editMode() {
      return this.origData !== undefined;
    },
  },
  methods: {
    handleClose(done) {
      done();
    },
    show() {
      this.drawer = true;
    },
    close(result) {
      this.reset();
      this.drawer = false;
      this.$emit('finish', result);
    },
    loadData(pro) {
      this.origData = pro;

      this.name = this.origData.name;
      this.email = this.origData.email;
      this.role = this.origData.role;
      this.$nextTick(() => {
        this.branch = this.origData.branches.map((b) => b.id) || [];
      });
    },
    reset() {
      this.origData = undefined;
      this.name = '';
      this.email = '';
      this.role = '';
      this.country = '+886';
      this.phone = '';
      this.branch = [];
    },
    cancel() {
      this.close(false);
    },
    save() {
      this.$execWithLoading(async () => {
        let { branch } = this;
        if (this.role !== 'staff') {
          branch = [];
        }
        if (this.editMode) {
          await updateAccount(
            this.origData.id,
            this.name, this.email, ' ', this.role, this.origData.enabled,
            branch,
          );
          this.$showSuccess('更新成功');
          this.close(true);
        } else {
          await newAccount(
            this.name, this.email, ' ', this.role, branch,
          );
          this.$showSuccess('新增成功');
          this.close(true);
        }
      }, (e) => {
        this.$showAxiosException('新增帳號', e);
      });
    },
  },
  mounted() {
    this.$on('show', (data) => {
      if (data) {
        this.loadData(data);
      }
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.drawer {
  padding: 20px;
  .button {
    flex: 1;
  }
  .data {
    .left {
      flex: 0 0 120px;
    }
    .right {
      flex: 1;
    }
  }
}
</style>
